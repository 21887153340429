// FLEXIBLE GRID SYSTEM
// --------------------------------------------------------------------
// Defaults
$sgs-row-width: 100% !default; // fluid container width
$sgs-row-max-width: 71.25rem !default; // max. container width
$sgs-columns: 12 !default; // no. of columns
$sgs-gutter: 0.9375rem !default; // spacing between columns

// Grid row (container)
@mixin grid-row(
    $row-limit: $sgs-row-max-width,
    $row-width: $sgs-row-width,
    $center: auto
) {
    margin-left: $center;
    margin-right: $center;
    @if $row-limit != 0 {
        max-width: $row-limit;
    }

    width: $row-width;
}

// Grid box
@mixin grid-box(
    $span-columns,
    $gutter: $sgs-gutter,
    $total-columns: $sgs-columns,
    $grid-type: 100%
) {
    box-sizing: border-box;
    float: left;
    padding-left: $gutter;
    padding-right: $gutter;
    width: $grid-type * ($span-columns / $total-columns);
}

// Push/pull grid box
@mixin grid-push(
    $push-columns,
    $total-columns: $sgs-columns,
    $grid-type: 100%
) {
    left: $grid-type * ($push-columns / $total-columns);
    position: relative;
}

@mixin grid-pull(
    $pull-columns,
    $total-columns: $sgs-columns,
    $grid-type: 100%
) {
    left: -($grid-type) * ($pull-columns / $total-columns);
    position: relative;
}

// Prefix/suffix grid box
@mixin grid-prefix(
    $prefix-columns,
    $total-columns: $sgs-columns,
    $grid-type: 100%
) {
    margin-left: ($grid-type) * ($prefix-columns / $total-columns);
}

@mixin grid-suffix(
    $suffix-columns,
    $total-columns: $sgs-columns,
    $grid-type: 100%
) {
    margin-right: ($grid-type) * ($suffix-columns / $total-columns);
}

// For nested grids
@mixin grid-nest {
    padding: 0;
}

// Last box of a row
@mixin grid-end {
    float: right;
}
