// SIDEBAR (WEBSITE)
// --------------------------------------------------------------------
// HOME: SUPPORT
#support {
    ul {
        @extend %plain-list;
    }
}

// DOCS: SITEMAP
#sitemap {
    > ul {
        margin: 1rem 0;
    }

    li > ul {
        padding-left: 1.25rem;
    }
}
