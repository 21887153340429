// SITE NAV
// --------------------------------------------------------------------
#nav-global {
    // LOGO: SERENDIPITY
    @media screen and (max-width: 29.9375em) {
        &::before {
            background: url('/img/logos/serendipity.png') 0 0 no-repeat;
            background-size: contain;
        }
    }

    // LOGO: FLOWER
    @media screen and (max-width: 29.9375em) {
        &::after {
            background: url('/img/logos/flower.png') 0 0 no-repeat;
            background-size: contain;
        }
    }

    @media screen and (min-width: $sm) {
        .layout-container {
            &::before {
                background: url('/img/logos/s9y.png') 0 0 no-repeat;
                background-size: contain;
            }
        }
    }
}
