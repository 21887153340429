// SIDEBAR
// --------------------------------------------------------------------
#sidebar {
    @include grid-box(12, 0.5rem);

    > h2 {
        @include visuallyhidden;
    }

    @media screen and (min-width: $sm) {
        @include grid-nest;
    }

    @media screen and (min-width: $lg) {
        @include grid-box(3, 1rem);
        @include grid-pull(9);
    }
}

.widget {
    @media screen and (min-width: $sm) {
        @include grid-box(6, 1rem);
    }

    @media screen and (min-width: $lg) {
        float: none;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}
