// HOME: FEATURES
// --------------------------------------------------------------------
#featurelist {
    @extend %plain-list;

    @include clearfix;

    h3 {
        margin: 0;
    }

    img {
        box-sizing: border-box;
        float: left;
        height: 32px;
        margin: 0 1rem 0 0;
        width: 32px;
    }

    li {
        @include clearfix;

        > p {
            clear: left;
        }

        @media screen and (min-width: $md) {
            @include grid-box(6);
        }

        &:nth-child(odd) {
            clear: left;
            padding-left: 0;
        }

        &:nth-child(even) {
            padding-right: 0;
        }
    }
}

.features-more {
    margin-bottom: 2rem;
    text-align: center;

    .button-link {
        margin: 0 0.4rem 0 0;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
