// FOOTER
// --------------------------------------------------------------------
#service {
    background-color: $off-white;
    color: $greyed-out;
    font-size: 0.875rem;
    padding-bottom: 1rem;
}

#service-links {
    @extend %plain-list;

    @include grid-box(12, 0.5rem);

    margin-bottom: 0;
    text-align: center;

    @media screen and (min-width: $sm) {
        @include grid-box(12, 1rem);

        margin: 1rem 0 0;
    }

    @media screen and (min-width: $lg) {
        @include grid-box(3, 1rem);

        margin: 1rem 0;
        text-align: left;
    }
}

#supporters {
    @include grid-box(12, 0.5rem);

    text-align: center;

    @media screen and (min-width: $sm) {
        @include grid-box(12, 1rem);
    }

    @media screen and (min-width: $lg) {
        @include grid-box(9, 1rem);

        text-align: left;
    }
}
