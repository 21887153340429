// COLORS clrs.cc
// --------------------------------------------------------------------
// Cool
$aqua: #7fdbff;
$blue: #0074d9;
$navy: #001f3f;
$teal: #39cccc;
$green: #2ecc40;
$olive: #3d9970;
$lime: #01ff70;

// Warm
$yellow: #ffdc00;
$orange: #ff851b;
$red: #ff4136;
$fuchsia: #f012be;
$purple: #b10dc9;
$maroon: #85144b;

// Gray Scale
$white: #fff;
$silver: #ddd;
$grey: #aaa;
$black: #111;

// Variations
$off-white: #fcfcfc;
$lite-grey: #eee;
$greyed-out: #888;
$off-black: #222;

// FLAT UI COLORS flatuicolors.com
// --------------------------------------------------------------------
$turquoise: #1abc9c;
$green-sea: #16a085;
$emerald: #2ecc71;
$nephritis: #27ae60;
$peter-river: #3498db;
$belize-hole: #2980b9;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$wet-asphalt: #34495e;
$midnight-blue: #2c3e50;
$sun-flower: #f1c40f;
$flat-orange: #f39c12;
$carrot: #e67e22;
$pumpkin: #d35400;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$clouds: #ecf0f1;
$flat-silver: #bdc3c7;
$concrete: #95a5a6;
$asbestos: #7f8c8d;
