// HIDE ELEMENTS
// --------------------------------------------------------------------
// Hide only visually, but have it available for screenreaders
@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// 'Undo' visuallyhidden
@mixin not-visuallyhidden {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// Hide visually and from screenreaders, but maintain layout
// (also useful for lazy-hiding items which require JS)
@mixin invisible {
    visibility: hidden;

    .no-js & {
        visibility: visible;
    }
}

// Hide from both screenreaders and browsers
@mixin hidden {
    display: none !important;
    visibility: hidden;
}

// CLEARFIX
// --------------------------------------------------------------------
@mixin clearfix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

// IMAGE REPLACEMENT
// --------------------------------------------------------------------
@mixin ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        height: 150%;
        width: 0;
    }
}
