// NAVIGATION STYLES
// --------------------------------------------------------------------
// Defaults
$nav-breakpoint: $md !default; // Breakpoint for smallscreen nav

// General nav styles
// --------------------------------------------------------------------
nav ul,
nav ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

// RESPONSIVE NAV ICON FALLBACK
// --------------------------------------------------------------------
@if not($use-svg-sprites) {
    .nav-toggle {
        @include icon-hamburger;
    }
}

// RESPONSIVE NAV
// --------------------------------------------------------------------
// github.com/viljamis/responsive-nav.js
@if $use-rnav-classes {
    .nav-collapse {
        ul,
        li {
            display: block;
            width: 100%;
        }

        .js & {
            clip: rect(0 0 0 0);
            display: block;
            max-height: 0;
            overflow: hidden;
            position: absolute;
            zoom: 1;
        }

        &.opened {
            max-height: 9999px;
        }
    }

    .disable-pointer-events {
        pointer-events: none !important;
    }

    .nav-toggle {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        user-select: none;
    }

    @media screen and (min-width: $nav-breakpoint) {
        .nav-collapse {
            li {
                width: auto;
            }
        }

        .js .nav-collapse {
            clip: auto;
            height: auto;
            margin: 0;
            max-height: none;
            overflow: visible;
            position: relative;
            width: auto;
        }

        .nav-toggle {
            display: none !important;
            visibility: hidden;
        }
    }
}
