// BREADCRUMBS
// --------------------------------------------------------------------
.breadcrumb {
    list-style: none;
    margin-bottom: 1em;
    padding: 8px 0;

    & > li {
        display: inline-block;

        & + li::before {
            color: #999;
            content: '» ';
            padding: 0 5px;
        }
    }

    & > .active {
        color: #777;
    }
}
