// ELEMENT STYLES NOT COVERED BY NORMALIZE
// --------------------------------------------------------------------
// Defaults
$selection-bg: #b3d4fc !default;
$hint-bg: $silver !default;
$hint-fg: $off-black !default;

::selection {
    background: $selection-bg;
    text-shadow: none;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0;
}

caption,
th {
    text-align: left;
}

table {
    margin: $type-rhythm 0;
    width: 100%;

    caption {
        text-align: center;
    }
}

li {
    ul,
    ol {
        margin: 0;
    }
}

hr {
    border: 0;
    border-top: 1px solid $silver;
    display: block;
    height: 1px;
    margin: 1.5rem 0;
    padding: 0;
}

summary {
    cursor: pointer;
}

// Remove the gap between images and the bottom of their containers
img {
    vertical-align: middle;
}

// mattstow.com/responsive-design-in-ie10-on-windows-phone-8.html
@-ms-viewport {
    width: device-width;
}

// BASIC TYPOGRAPHY
// --------------------------------------------------------------------
%primary-font {
    font-family: $primary-font;
}

html {
    @extend %primary-font;

    font-size: 100%;
}

@if $use-form-styles {
    button,
    input,
    select,
    textarea {
        @extend %primary-font;
    }
}

@if $use-form-advanced {
    optgroup {
        @extend %primary-font;
    }
}

body {
    line-height: $type-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: $type-rhythm 0;
}

h5,
h6 {
    font-size: 1rem;
}

pre,
code,
samp,
kbd {
    font-size: 0.875rem;
}

pre code {
    font-size: 1em;
}

// QUOTES
// html5doctor.com/blockquote-q-cite/
// --------------------------------------------------------------------
q {
    quotes: '“' '”' '‘' '’';
}

:lang(de) q {
    quotes: '„' '“' '‚' '‘';
}

// HYPHENATION
// --------------------------------------------------------------------
p {
    hyphens: auto;

    // not for preformatted text (inline)
    code,
    samp,
    kbd {
        hyphens: none;
    }
}

// EXTENDS/INCLUDES
// --------------------------------------------------------------------
video,
img {
    @include responsive-media;
}

// ENABLE JS HINT
// --------------------------------------------------------------------
@if $use-enable-js {
    #enable-js {
        background: $hint-bg;
        color: $hint-fg;
        left: 0;
        margin: 0;
        padding: 4px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        .js & {
            @include hidden;
        }
    }

    .no-js {
        padding-top: 32px;
    }
}
