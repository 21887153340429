// TYPOGRAPHY
// --------------------------------------------------------------------
$primary-font: 'Lato', sans-serif;
$type-height: 1.5;
$type-rhythm: 1rem;

// BREAKPOINTS
// --------------------------------------------------------------------
$sm: 30em; //  480px
$md: 48em; //  768px
$lg: 64em; // 1024px
$xl: 90em; // 1440px
$xx: 120em; // 1920px

// COLORS
// --------------------------------------------------------------------
$s9y-blue-lite: #ccdee7;
$s9y-blue-dark: #374d76;
$s9y-blue-very: #25253d;
$s9y-orange: #ffbf00;

// OVERRIDES
// --------------------------------------------------------------------
$msg-class-prefix: serendipity_;
$msg-class-warning: msg_important;
$msg-class-success: msg_notice;
$msg-class-notice: commentDirection;
$msg-class-question: question;
