// SELF-HOSTED WEBFONTS
// --------------------------------------------------------------------
$font-path: '../fonts/' !default;

@mixin webfont(
    $family,
    $src,
    $style: normal,
    $weight: normal,
    $path: $font-path
) {
    @font-face {
        font-family: $family;
        font-style: $style;
        font-weight: $weight;
        src: url('#{$path}#{$src}.woff2') format('woff2'),
            url('#{$path}#{$src}.woff') format('woff'),
            url('#{$path}#{$src}.ttf') format('truetype');
    }
}

@include webfont('Lato', Lato-Regular, normal, normal);
@include webfont('Lato', Lato-Italic, italic, normal);
@include webfont('Lato', Lato-Bold, normal, bold);
@include webfont('Lato', Lato-BoldItalic, italic, bold);
