// BUTTONS
// --------------------------------------------------------------------
// Defaults
$button-radius: 4px !default;
$button-focus: #06f !default;
$button-padding: 0.1875rem 0.625rem !default;

// Gradient button
@mixin gradient-button(
    $bg-color: $silver,
    $fg-color: $black,
    $radius: $button-radius
) {
    background-clip: padding-box;
    background-color: lighten($bg-color, 13.2%);
    background-image: linear-gradient(
        to bottom,
        lighten($bg-color, 13.2%),
        $bg-color
    );
    border: 1px solid;
    border-color: $bg-color darken($bg-color, 13.2%) darken($bg-color, 26.5%);
    border-radius: $radius;
    color: $fg-color;
    display: inline-block;
    line-height: normal;
    outline: 0;
    padding: $button-padding;
    text-align: center;
    text-decoration: none;
    text-shadow: invert($fg-color) 0 1px 1px;
    width: auto;

    &:hover {
        background-color: lighten($bg-color, 13.2%);
        background-image: linear-gradient(
            to bottom,
            lighten($bg-color, 13.2%),
            darken($bg-color, 6.5%)
        );
    }

    &:active {
        background-color: lighten($bg-color, 13.2%);
        background-image: linear-gradient(
            to bottom,
            lighten($bg-color, 13.2%),
            darken($bg-color, 6.5%)
        );
        border-color: darken($bg-color, 26.5%) darken($bg-color, 13.2%)
            $bg-color;
        box-shadow: inset rgba($black, 0.25) 0 1px 2px 0;
    }

    &:focus {
        box-shadow: $button-focus 0 0 5px 0;
        z-index: 1;
    }

    &:active,
    &:visited,
    &:hover,
    &:focus {
        color: $fg-color;
    }
}

// Flat button
@mixin flat-button(
    $bg-color: $silver,
    $fg-color: $black,
    $radius: $button-radius,
    $alt-color: complement($bg-color)
) {
    background: $bg-color;
    background-clip: padding-box;
    border: 1px solid $bg-color;
    border-radius: $radius;
    color: $fg-color;
    display: inline-block;
    line-height: normal;
    outline: 0;
    padding: $button-padding;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    width: auto;

    &:active,
    &:hover,
    &:focus {
        background: $alt-color;
        border-color: $alt-color;
        color: $fg-color;
    }

    &:focus {
        box-shadow: none;
        z-index: auto;
    }
}

// Border-button
@mixin border-button(
    $fg-color: $black,
    $alt-color: $white,
    $border-width: 1px,
    $radius: $button-radius
) {
    background: transparent;
    background-clip: padding-box;
    border: $border-width solid $fg-color;
    border-radius: $radius;
    color: $fg-color;
    display: inline-block;
    line-height: normal;
    outline: 0;
    padding: $button-padding;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    width: auto;

    &:active,
    &:hover,
    &:focus {
        background: $fg-color;
        color: $alt-color;
    }

    &:focus {
        box-shadow: none;
        z-index: auto;
    }
}
