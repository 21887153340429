// GLOBAL STYLES
// --------------------------------------------------------------------
html {
    background-color: $off-white;
    color: $off-black;

    @media screen and (min-width: $xx) {
        font-size: 112.5%;
    }
}

body {
    background-color: $s9y-blue-lite;

    @media screen and (min-width: $md) {
        padding-top: 2rem;
    }
}

a {
    &,
    &:visited {
        color: $s9y-blue-dark;
    }

    &:hover,
    &:focus {
        color: $s9y-blue-very;
    }
}

ul {
    padding-left: 1.25rem;
}

blockquote {
    border-left: 0.5rem solid $s9y-blue-dark;
    margin: 1rem 2rem;
    padding: 0.5rem 0 0.5rem 2rem;
}

// GENERIC CLASSES + EXTENDS
// --------------------------------------------------------------------
%plain-list {
    list-style: none;
    padding: 0;
}

.layout-container {
    @include clearfix;

    @include grid-row;
}

.button-link {
    @include flat-button($s9y-blue-dark, $white, 4px, $s9y-blue-very);

    box-sizing: border-box;
    line-height: 1.5;
    padding: 0.25rem 1rem;
    transition: all 0.25s linear;

    &,
    &:visited {
        color: $white;
    }

    &:hover {
        transition: all 0.25s linear;
    }
}

// GLOBAL: CONTENT
// --------------------------------------------------------------------
body > main {
    background: $off-white;

    .layout-container {
        border-bottom: 1px solid $silver;

        @media screen and (min-width: $lg) {
            padding-top: 1rem;
        }
    }
}

figure {
    img {
        border: 1px solid $s9y-blue-dark;
        box-sizing: border-box;
        margin: 0;
    }
}

figcaption {
    box-sizing: border-box;
    color: $greyed-out;
    font-size: 0.8125rem;
    padding: 0.3125rem 0 0;
    text-align: center;
}

#content {
    @include grid-box(12, 0.5rem);

    p {
        text-align: justify;
    }

    img {
        @include responsive-media;
    }

    pre {
        background-color: $lite-grey;
        border: 1px solid $silver;
        box-sizing: border-box;
        overflow-x: scroll;
        padding: 0.5rem;
    }

    @media screen and (min-width: $sm) {
        @include grid-box(12, 1rem);
    }

    @media screen and (min-width: $lg) {
        @include grid-box(9, 1rem);
        @include grid-push(3);
    }
}

.content-image {
    border: 1px solid $s9y-blue-dark;
    box-sizing: border-box;
    margin: 1rem 0;
}
