// FEATURES
// --------------------------------------------------------------------
#featurethemes {
    @include clearfix;

    img {
        float: left;
        margin-right: 1%;
        width: 48%;
    }
}

#features {
    @extend %plain-list;

    @include clearfix;

    margin-top: -1rem;

    h3 {
        margin: 0;
    }

    img {
        border: 1px solid $s9y-blue-dark;
        box-sizing: border-box;
        margin: 0 1rem 0 0;
        opacity: 1;
        transition: opacity 0.25s linear;
        width: 100%;

        &:hover {
            opacity: 0.5;
            transition: opacity 0.25s linear;
        }
    }

    li {
        @include clearfix;

        margin-top: 1rem;

        > p {
            clear: left;
        }

        @media screen and (min-width: $md) {
            @include grid-box(6);

            &:nth-child(odd) {
                clear: left;
                padding-left: 0;
            }

            &:nth-child(even) {
                padding-right: 0;
            }
        }
    }
}
